import "./AdmPanelCategories.css"

import { useState } from "react"

// Firebase
import { db } from "../../firebase-config"
import { collection, addDoc, deleteDoc, doc, updateDoc } from "firebase/firestore"

const AdmPanelCategories = (categories) => {

    const [displayNewPost, setDisplayNewPost] = useState(false)
    const [postTitle, setPostTitle] = useState(null)

    // Upload Category
    const uploadCategory = async() => {
        const docRef = await addDoc(collection(db, "categories"), {
            name: postTitle,
            listed: false,
        })

        setPostTitle(null)
        setDisplayNewPost(false)
    }

    // Set Listed
    const listCategory = async(post) => {
        let boolListed = null
        if(post.listed == true){
            boolListed = false
        }else if(post.listed == false){
            boolListed = true
        }

        await updateDoc(doc(db, "categories", post.id), {
            listed: boolListed
        });
    }

    return (
        <section className="admpanel-categories-main">
                {displayNewPost && 
                    <div className="newPostMain">
                        <div className="newPostMainFlex">
                            <input type="text" placeholder="Nome da Categoria" onChange={(e)=>{setPostTitle(e.target.value)}}/>
                            <div className="button-holder-left">
                                <button onClick={uploadCategory}>Adicionar</button>
                            </div>
                        </div>
                    </div>
                }
                <div className="button-holder">
                    <button onClick={()=>setDisplayNewPost(true)}>Nova Categoria</button>
                </div>
                <ul>
                    {categories && categories.categories.map((post,index)=>(
                        <li key={index}>
                            <p>{post.name}</p>
                            <span>Listar categoria na home: <div className="listed" onClick={()=>listCategory(post)}>{post.listed && <div />}</div></span>
                        </li>
                    ))}
                </ul>
        </section>
    )
}

export default AdmPanelCategories