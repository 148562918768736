import { useContext, createContext } from "react";

const PostsContext = createContext()

export function PostsProvider({children, value}) {
    return <PostsContext.Provider value={value}>{children}</PostsContext.Provider>
}

export function usePostsValue(){
    return useContext(PostsContext)
}