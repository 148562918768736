import "./Values.css"

const Values = () => {
  return (
    <section className="values-main">
        <div className="values-content">
            <h3>agili<br />dade</h3>
        </div>
        <div className="values-content">
            <h3>par<br />ceria</h3>
        </div>
        <div className="values-content">
            <h3>cam<br />peões</h3>
        </div>
    </section>
  )
}

export default Values