import "./Units.css";
import "swiper/css";

import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { unidades } from "../../data/unidades";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

//Components
import PageTitle from "../../components/PageTitle/PageTitle";
import GoogleMaps from "../../components/GoogleMaps/GoogleMaps";

const Units = () => {
    let arrayReg = [];
    const [regionSelectIsActive, setRegionSelectActive] = useState(false);
    const [modSelectIsActive, setModSelectIsActive] = useState(false);
    const [selectedReg, setSelectedReg] = useState("Todas");
    const [selectedMod, setSelectedMod] = useState("Todas");
    const [arrayFiltered, setArrayFiltered] = useState([]);

    //Swiper

    //Array unidades região
    const arrayRegion = () => {
        for (let i = 0; i < unidades.length; i++) {
            let item = arrayReg.find(
                (element) => element === unidades[i].regiao
            );
            !item && arrayReg.push(unidades[i].regiao);
        }
    };

    arrayRegion();

    //Filters
    useEffect(() => {
        const filterUnits = () => {
            let q = [];

            switch (selectedMod) {
                case "beachvolei":
                    q = unidades.filter((unit) => unit.beachvolei === true);
                    break;
                case "beachtennis":
                    q = unidades.filter((unit) => unit.beachtennis === true);
                    break;
                case "futevolei":
                    q = unidades.filter((unit) => unit.futevolei === true);
                    break;
                case "funcional":
                    q = unidades.filter((unit) => unit.funcional === true);
                    break;
                default:
                    q = unidades;
            }

            if (selectedReg !== "Todas") {
                q = q.filter((unit) => unit.regiao === selectedReg);
            }

            setArrayFiltered(q);
        };

        filterUnits();
    }, [selectedMod, selectedReg]);

    // Filter Reset
    const resetFilter = () => {
        setSelectedMod("Todas");
        setSelectedReg("Todas");
    };

    // Images Render
    const renderImgs = (unidade) => {
        if (unidade["imagens"]) {
            return (
                <Swiper
                    navigation={true}
                    modules={[Navigation]}
                    className="mySwiper"
                >
                    {unidade["imagens"].map((img, index) => (
                        <SwiperSlide key={index} className="x">
                            <img src={img} alt={unidade.unidade} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            );
        } else {
            return <p>Sem imagens</p>;
        }
    };

    return (
        <div className="units-main">
            <PageTitle value="Unidades" />
            {/* <GoogleMaps /> */}
            <section className="all-units">
                <div className="all-units-topbar">
                    <h3>Todas as unidades</h3>
                    <span className="filter-title">Filtrar por</span>
                    <div className="filter-holder">
                        <div>
                            <div
                                className="select-box"
                                onClick={() => {
                                    setRegionSelectActive(
                                        !regionSelectIsActive
                                    );
                                }}
                            >
                                <div
                                    className={
                                        regionSelectIsActive
                                            ? "select-box-topbar active"
                                            : "select-box-topbar"
                                    }
                                >
                                    <span className="select-box-title">
                                        Região
                                    </span>
                                    <span className="selected">
                                        {selectedReg}
                                    </span>
                                </div>
                                {regionSelectIsActive && (
                                    <ul>
                                        <li
                                            value="Todas"
                                            onClick={(e) => {
                                                setSelectedReg(
                                                    e.target.textContent
                                                );
                                            }}
                                        >
                                            Todas
                                        </li>
                                        {arrayReg.map((regiao, index) => (
                                            <li
                                                key={index}
                                                value={regiao}
                                                onClick={(e) => {
                                                    setSelectedReg(
                                                        e.target.textContent
                                                    );
                                                }}
                                            >
                                                {regiao}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </div>
                        <div>
                            <div
                                className="select-box"
                                onClick={() => {
                                    setModSelectIsActive(!modSelectIsActive);
                                }}
                            >
                                <div
                                    className={
                                        modSelectIsActive
                                            ? "select-box-topbar active"
                                            : "select-box-topbar"
                                    }
                                >
                                    <span className="select-box-title">
                                        Modalidades
                                    </span>
                                    <span className="selected">
                                        {selectedMod}
                                    </span>
                                </div>
                                {modSelectIsActive && (
                                    <ul>
                                        <li
                                            value="Todas"
                                            onClick={(e) => {
                                                setSelectedMod("Todas");
                                            }}
                                        >
                                            Todas
                                        </li>
                                        <li
                                            value="Todas"
                                            onClick={(e) => {
                                                setSelectedMod("beachvolei");
                                            }}
                                        >
                                            Beach Volley
                                        </li>
                                        <li
                                            value="Todas"
                                            onClick={(e) => {
                                                setSelectedMod("beachtennis");
                                            }}
                                        >
                                            Beach Tennis
                                        </li>
                                        <li
                                            value="Todas"
                                            onClick={(e) => {
                                                setSelectedMod("futevolei");
                                            }}
                                        >
                                            Futevôlei
                                        </li>
                                        <li
                                            value="Todas"
                                            onClick={(e) => {
                                                setSelectedMod("funcional");
                                            }}
                                        >
                                            Funcional
                                        </li>
                                    </ul>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="all-units-container">
                    <ul>
                        {arrayFiltered &&
                            arrayFiltered.map((unidade, index) => (
                                <li
                                    key={index}
                                    style={{ position: "relative" }}
                                >
                                    {unidade.isCommingSoon && (
                                        <div
                                            style={{
                                                position: "absolute",
                                                top: 10,
                                                right: 10,
                                                zIndex: 2,
                                                padding: "5px 15px",
                                                borderRadius: 40,
                                                backgroundColor: "#FF9E1B",
                                                fontWeight: "700",
                                                fontSize: "12px",
                                                textTransform: "uppercase",
                                            }}
                                        >
                                            Em breve!
                                        </div>
                                    )}

                                    <div className="unidade-image">
                                        {renderImgs(unidade)}
                                    </div>
                                    <div className="unidade-content">
                                        <h6>{unidade.unidade}</h6>
                                        <span>{unidade.endereco}</span>
                                        <span>{unidade.cidade}</span>
                                        <div className="unidade-available-modalities">
                                            {unidade.beachvolei == true && (
                                                <div className="mod-available beachvolei">
                                                    Beach Volley
                                                </div>
                                            )}
                                            {unidade.beachtennis == true && (
                                                <div className="mod-available beachtennis">
                                                    Beach Tennis
                                                </div>
                                            )}
                                            {unidade.futevolei == true && (
                                                <div className="mod-available futevolei">
                                                    Beach Futevôlei
                                                </div>
                                            )}
                                            {unidade.funcional == true && (
                                                <div className="mod-available funcional">
                                                    Funcional
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {unidade.whatsapp && (
                                        <a
                                            href={unidade.whatsapp}
                                            target="_blank"
                                            className="unit-whatsapp"
                                        >
                                            whatsapp da unidade
                                        </a>
                                    )}
                                </li>
                            ))}
                        {arrayFiltered.length === 0 && (
                            <div className="not-found">
                                <p>
                                    Não encontramos unidades para estes filtros
                                </p>
                                <button onClick={resetFilter}>
                                    Ver todas as unidades
                                </button>
                            </div>
                        )}
                    </ul>
                </div>
            </section>
        </div>
    );
};

export default Units;
