import "./ModalitieDetail.css"

import PageTitle from "../../components/PageTitle/PageTitle"
import { unidades } from "../../data/unidades"
import { useState, useEffect } from "react"


const ModalitieDetail = (props) => {

    const [arrayFiltered, setArrayFiltered] = useState([])

    useEffect(() => {
        
        const filterUnits = () => {
      
            let q = []
      
            switch (props.value) {
              case "beachvolei":
                q = unidades.filter(unit => unit.beachvolei === true)
                break;
              case "beachtennis":
                q = unidades.filter(unit => unit.beachtennis === true)
                break;
              case "futevolei":
                q = unidades.filter(unit => unit.futevolei === true)
                break;
              case "funcional":
                q = unidades.filter(unit => unit.funcional === true)
                break;
              default:
                q = unidades
            }
      
            setArrayFiltered(q)
      
          }
      
          filterUnits()

    }, [])


    return (
        <section className={`modalitie-detail-main ${props.value}`}>
            <PageTitle value={props.sport} />
            <div className="modalitie-detail-text">
                <p>{props.text}</p>
            </div>
            <div className="modalitie-detail-imgs">
                <div>
                  <img src={props.img1} alt="" />
                </div>
                <div>
                  <img src={props.img2} alt="" />
                </div>
                <div>
                  <img src={props.img3} alt="" />
                </div>
            </div>
            <div className="modalitie-units">
                <p>Unidades disponíveis:</p>
                <ul>
                    {arrayFiltered.map((unidade,index)=>(
                        <li key={index}><a href={unidade.whatsapp} target="_blank">{unidade.unidade}</a></li>
                    ))}
                </ul>
            </div>
        </section>
    )
}

export default ModalitieDetail