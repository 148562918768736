import CtaLink from "../CtaLink/CtaLink"
import "./RiplayStoreCall.css"

const RiplayStoreCall = () => {
  return (
    <section className="riplay-store-call-main">
        <h3>Loja oficial<span>Riplay Sports</span></h3>
        <div>
            <CtaLink label="Visite nossa loja" align="center" />
        </div>
    </section>
  )
}

export default RiplayStoreCall