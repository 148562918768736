import "./CtaLink.css"

import { Link } from "react-router-dom"
 
export const CtaLink = (props) => {
  return (
    <>
        <Link to={props.linkto ? props.linkto : ""} className={"cta-link" + " " + (props.align ? props.align : "")}>
          {props.label ? props.label : "Saiba mais"}
        </Link>
    </>
  )
}

export default CtaLink