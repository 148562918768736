import "./BeRiplay.css"

import {CtaLink} from "../../components/CtaLink/CtaLink"

const BeRiplay = () => {
  return (
    <section className="beriplay-main">
        <div className="beriplay-content">
            <h3>
                <span>
                    Vem<br />ser<br />
                </span>
                Riplay
            </h3>
            <CtaLink label="conheça a riplay" linkto="/sobre-nos" />
        </div>
    </section>
  )
}

export default BeRiplay