import "./AdmPanelAuthors.css"

import { useState } from "react"

// Firebase
import { db } from "../../firebase-config"
import { collection, addDoc, deleteDoc, doc } from "firebase/firestore"

const AdmPanelAuthors = (authors) => {

    const [displayNewPost, setDisplayNewPost] = useState(false)
    const [postTitle, setPostTitle] = useState(null)
    const [postImg, setPostImg] = useState(null)
    const [postBody, setPostBody] = useState(null)
    const [deleteAuthorName, setDeleteAuthorName] = useState(null)
    const [deleteAuthorId, setDeleteAuthorId] = useState(null)

    // Upload Author
    const uploadAuthor = async() => {
        const docRef = await addDoc(collection(db, "authors"), {
            name: postTitle,
            img: postImg,
            body: postBody,
        })

        setPostTitle(null)
        setPostImg(null)
        setPostBody(null)
        setDisplayNewPost(false)
    }

    const SetDeleteAuthor = async (post, name) => {
        setDeleteAuthorName(name)
        setDeleteAuthorId(post)
    }

    const DeleteAuthor = async (post) => {
        await deleteDoc(doc(db, "authors", deleteAuthorId));
        setDeleteAuthorId(null)
    }

    return (
        <section className="admpanel-authors-main">
            {deleteAuthorId &&
                <div className="modal-confirm">
                    <div className="modal-confirm-content">
                        <h2>Apagar permanentemente ? <span>{deleteAuthorName}</span></h2>
                        <div className="buttons">
                            <button onClick={()=>setDeleteAuthorId(null)}>Cancelar</button>
                            <button onClick={DeleteAuthor}>Apagar</button>
                        </div>
                    </div>
                </div>
            }
            {displayNewPost && 
                <div className="newPostMain">
                    <div className="newPostMainFlex">
                        <input type="text" placeholder="Nome" onChange={(e)=>{setPostTitle(e.target.value)}}/>
                        <input type="text" placeholder="Url da imagem (recomendado imagem quadrada)"  onChange={(e)=>{setPostImg(e.target.value)}}/>
                        <textarea placeholder="Contéudo" onChange={(e)=>{setPostBody(e.target.value)}}>

                        </textarea>
                        <div className="button-holder-left">
                            <button onClick={uploadAuthor}>Adicionar</button>
                        </div>
                    </div>
                </div>
            }
            <div className="button-holder">
                <button onClick={()=>setDisplayNewPost(true)}>Novo Autor</button>
            </div>
            <ul>
                {authors && authors.authors.map((post,index)=>(
                    <li key={index}>
                        <button onClick={()=>SetDeleteAuthor(post.id, post.name)}>Deletar</button>
                        <div className="image">
                            <img src={post.img} alt={post.name} />
                        </div>
                        <div className="content">
                            <span>{post.name}</span>
                            <p>
                                {post.body} 
                            </p>
                        </div>
                    </li>
                ))}
            </ul>
        </section>
    )
}

export default AdmPanelAuthors