import "./Partners.css"

import partnersImg from "../../assets/about/partners.png"

const Partners = () => {
  return (
    <section className="partners-main">
        <h4>Nossos <span>Parceiros</span></h4>
        <img src={partnersImg} alt="Parceiros" />
    </section>
  )
}

export default Partners