import "./AdmPanelPosts.css"

import { useState } from "react"
import {Link} from "react-router-dom"

// Images
import paragraphExample from "../../assets/admin/paragraph.png"

// Firebase
import { db } from "../../firebase-config"
import { collection, addDoc, doc, setDoc, Timestamp, deleteDoc } from "firebase/firestore"

const AdmPanelPosts = (posts) => {

    const [displayNewPost, setDisplayNewPost] = useState(false)
    const [postTitle, setPostTitle] = useState(null)
    const [postImg, setPostImg] = useState(null)
    const [postTags, setPostTags] = useState(null)
    const [postBody, setPostBody] = useState(null)
    const [postCategory, setPostCategory] = useState(null)
    const [postAuthor, setPostAuthor] = useState(null)
    const [postAuthorImg, setPostAuthorImg] = useState(null)
    

    const uploadPost = async() => {
        let findAuthor = posts.authors.find(author => author.name == postAuthor)
        let tags = postTags.replace(/\s/g, '').split(',')

        const docRef = await addDoc(collection(db, "posts"), {
            title: postTitle,
            img: postImg,
            body: postBody,
            category: postCategory,
            author: findAuthor.name,
            authorImg: findAuthor.img, 
            authorDesc: findAuthor.body,
            postTags: tags,
            createdAt: Timestamp.now(),
          })

          setPostTitle(null)
          setPostImg(null)
          setPostAuthorImg(null)
          setPostBody(null)
          setDisplayNewPost(false)
    }

    const FormatDate = (date) => {
        let newdate = new Date(date*1000).toString()
        let newdateArray = newdate.split(" ")
        newdateArray.splice(0,1)
        newdateArray = newdateArray.splice(0,3)
        let formatedDate = [newdateArray[1], newdateArray[0], newdateArray[2]].toString().replace(/,/g, ' ')
        
        return formatedDate
    }

    // Post Array Reversed
    const postArray = [...posts.posts].reverse()

    // Delete Post
    const DeletePost = async (post) => {
        await deleteDoc(doc(db, "posts", post.id));
    }

    return (
        <section className="admpanel-posts-main">
            {displayNewPost && 
                <div className="newPostMain">
                    <div className="newPostMainFlex">
                        <input type="text" placeholder="Título do Post" onChange={(e)=>{setPostTitle(e.target.value)}}/>
                        <input type="text" placeholder="Url da imagem"  onChange={(e)=>{setPostImg(e.target.value)}}/>
                        <input type="text" placeholder="Tags separadas por vírgula"  onChange={(e)=>{setPostTags(e.target.value)}}/>
                        <select placeholder="Categoria" defaultValue="" onChange={(e)=>{setPostCategory(e.target.value)}}>
                            <option value="" disabled>Selecione a categoria:</option>
                            {posts && posts.categories.map((category, index)=>(
                                <option key={index} value={category.name}>{category.name}</option>
                            ))}
                        </select>
                        <select placeholder="Author" defaultValue="" onChange={(e)=>{setPostAuthor(e.target.value)}}>
                            <option value="" disabled>Selecione o autor:</option>
                            {posts && posts.authors.map((author, index)=>(
                                <option key={index} value={author.name}>{author.name}</option>
                            ))}
                        </select>
                        <textarea placeholder="Contéudo" onChange={(e)=>{setPostBody(e.target.value)}}>
                        </textarea>
                        <span className="create-paragraphs">
                            Para criar parágrafos escreva:
                            <img src={paragraphExample} alt="paragrafo" />
                        </span>
                        <div className="button-holder-left">
                            <button onClick={uploadPost}>Postar</button>
                        </div>
                    </div>
                </div>
            }
            <div className="button-holder">
                <button onClick={()=>setDisplayNewPost(true)}>Novo Post</button>
            </div>
            <ul>
                {postArray && postArray.map((post)=>(
                    <li key={post.id}>
                        <div className="title">
                            <span>{post.category}</span>
                            <Link to={`/post/${post.id}`}>
                                <h4>{post.title}</h4>
                            </Link>
                        </div>
                        <div className="author">
                            <span className="date">{FormatDate(post.createdAt.seconds)}</span>
                            <span className="authorName">{post.author}</span>
                        </div>
                        <button onClick={()=>DeletePost(post)}>X</button>
                    </li>
                ))}
            </ul>
        </section>
    )
}

export default AdmPanelPosts