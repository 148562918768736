import React from 'react'
import { useLocation } from 'react-router-dom'

const Page404 = () => {
    const location = useLocation();
    console.log(location.pathname)

    //Redirect react Router
    switch (location) {
      case "adsda":
        
        break;
    
      default:
        break;
    }

  return (
    <div>Page404</div>
  )
}

export default Page404