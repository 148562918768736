// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC4FBSTxju9lo4Iy3ET2zXV_tg-zyj9wj4",
  authDomain: "blog-riplay.firebaseapp.com",
  projectId: "blog-riplay",
  storageBucket: "blog-riplay.appspot.com",
  messagingSenderId: "283088094737",
  appId: "1:283088094737:web:9e7837bc56c7f2d41193b0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Export
export const auth = getAuth(app)
export const db = getFirestore(app)