import "./OurUnitsCall.css"
import ourUnitsBg from "../../assets/ourUnits/our-units-bg.png"
import CtaLink from "../CtaLink/CtaLink"

const OurUnitsCall = () => {
  return (
    <section className="our-units-call-main">
        <div className="our-units-call-container">
            <div className="circle-shape"></div>
            <div className="our-units-call-content">
                <h3>
                    CONHEÇA <span>NOSSAS<br />UNIDADES </span>
                    E AGENDE<br />
                    SUA AULA TESTE
                </h3>
                <div className="link-holder">
                    <CtaLink label="veja nossas unidades" linkto="/unidades" align="right" />
                </div>
                <div className="link-holder mobile">
                    <CtaLink label="veja nossas unidades" linkto="/unidades" align="center" />
                </div>
            </div>
            <img src={ourUnitsBg} alt="Riplay Unidades" className="our-units-call-img" />
        </div>
    </section>
  )
}

export default OurUnitsCall