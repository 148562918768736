import "./Blog.css"

// Components
import { useState, useEffect } from "react"
import PostsSection from "../../components/PostsSection/PostsSection"
import BlogNavBar from "../../components/BlogNavBar/BlogNavBar"

// Context
import { usePostsValue } from "../../context/PostsContext"
import { Link } from "react-router-dom"

const Blog = () => {

  const { posts, categories } = usePostsValue() //Get Posts by Context
  const [ reversedPosts, setReversedPosts ] = useState(null);

  useEffect(() => {

  const ReOrderPosts = () => {
    const postArray = [...posts].reverse()
    setReversedPosts(postArray);
  }

  posts && ReOrderPosts()
  }, [posts])

  const FormatDate = (date) => {
    let newdate = new Date(date*1000).toString()
    let newdateArray = newdate.split(" ")
    newdateArray.splice(0,1)
    newdateArray = newdateArray.splice(0,3)
    let formatedDate = [newdateArray[1], newdateArray[0], newdateArray[2]].toString().replace(/,/g, ' ')
    
    return formatedDate
  }


  return (
    <div className="blog-feed">
        <BlogNavBar />
        <div className="blog-content">
            <div className="latest-posts">
                <h1><span>Últimos</span> Posts</h1>
                <ul>
                  {reversedPosts && reversedPosts.slice(0,10).map((post, index)=>(
                    <li key={index}>
                      {index <= 1 && <img src={post.img} alt={post.title} />}
                      {index <= 1 &&
                        <div className="post-content-highlight">
                          <h2>
                            <span>
                              {post.category}
                            </span>
                            {post.title}
                          </h2>
                          <div className="post-author-date">
                            <p>
                              <span>Escrito por:</span>
                              {post.author}
                            </p>
                            <span>
                              {FormatDate(post.createdAt.seconds)}
                            </span>
                          </div>
                        </div>
                      }
                      {index > 1 &&
                        <div className="post-content-main">
                          <div className="title">
                            <span className="date">{FormatDate(post.createdAt.seconds)}</span>
                            <h2>
                              <span>
                                {post.category}
                              </span>
                              {post.title}
                            </h2>
                          </div>
                          <p className="author">
                            <span>Escrito por:</span>
                            {post.author}
                          </p>
                        </div>
                      }
                      <Link to={`/post/${post.id}`} className="post-hover">
                        <svg xmlns="http://www.w3.org/2000/svg" width="23.041" height="23.084" viewBox="0 0 23.041 23.084">
                          <g id="Grupo_170" data-name="Grupo 170" transform="translate(-323.086 -1166.83)">
                            <path id="Caminho_296" data-name="Caminho 296" d="M0,0,7.832,8,0,16" transform="translate(327.568 1174.075) rotate(-45)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                            <line id="Linha_52" data-name="Linha 52" x1="14.078" y2="14.037" transform="translate(324.5 1174.463)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="2"/>
                          </g>
                        </svg>
                      </Link>               
                    </li>
                  ))}
                </ul>
                <Link to={`/blog/todos`} className="display-all-posts">Ver todos</Link>
            </div>
            {categories && categories.map((category, index)=>(
              <PostsSection key={index} category={category.name} />
            ))}
        </div>
    </div>
  )
}

export default Blog