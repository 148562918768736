import CtaLink from "../CtaLink/CtaLink"
import "./ModalitiesCards.css"

import { useNavigate } from "react-router-dom"

const ModalitiesCards = () => {

    const navigate = useNavigate()

    const modalitiesCardsClick = () => {
        navigate('/modalidades');
    }

    return (
        <section className="modalities-cards-main">
            <ul>
                <li onClick={()=>modalitiesCardsClick()}>Be<br />ach<br />ten<br />nis</li>
                <li onClick={()=>modalitiesCardsClick()}>Fu<br />te<br />vô<br />lei</li>
                <li onClick={()=>modalitiesCardsClick()}>Be<br />ach<br />vol<br />ley</li>
                <li onClick={()=>modalitiesCardsClick()}>Fun<br />cio<br />nal</li>
            </ul>
            <CtaLink label="Veja as modalidades" linkto="/modalidades" align="center" />
        </section>
    )
}

export default ModalitiesCards