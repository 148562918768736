import "./PageTitle.css"

const PageTitle = (props) => {
  return (
    <section className="page-title">
         <h2>{props.value}</h2>
    </section>
  )
}

export default PageTitle