import "./Franchise.css"
import { useState, useEffect, useRef } from "react"
import emailjs from '@emailjs/browser';
import Differentials from "../../components/Differentials/Differentials"

const numbers = [
  {
    num: "10",
    title: "Unidades",
    desc: "espalhadas no brasil e no mundo",
  },
  {
    num: "1.5K",
    title: "Pessoas",
    desc: "passam por aqui, aprendendo e evoluindo",
  },
  {
    num: "1.5K",
    title: "Alunos Matriculados",
    desc: "e cadastrados em nossa base de crm",
  },
  {
    num: "15",
    title: "Anos",
    desc: "de areia nos pés",
  },
  {
    num: "100+",
    title: "Professores",
    desc: "formados pelo curso da riplay beach sports",
  },
  {
    num: "300+",
    title: "Eventos",
    desc: "e campeonatos",
  },
  {
    num: "63.5K+",
    title: "Instagram",
    desc: "de seguidores",
  },
  {
    num: "32.1K+",
    title: "TikTok",
    desc: "de areia nos pés",
  },
]

const Franchise = () => {

  const [highlightActive, setHighlightActive] = useState(true)
  const [emailSuccess, setEmailSuccess] = useState(false)
  const delay = 5000;
  
  useEffect(() => {
    setTimeout(() => {
      setHighlightActive(false)
    }, delay)
  }, [])
  

  //Scroll To Form (CTA Btn click behavior)
  const scrollToForm = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    setHighlightActive(true)
  }

    const form = useRef();
  
    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_bc4f26j', 'template_paszq1b', form.current, 'HLv3dtry2ORETRo4v')
        .then((result) => {
            console.log(result.text);
            setEmailSuccess(true)
        }, (error) => {
            console.log(error.text);
        });
    };

  return (
    <div className="franchise-main">
        <section className="franchise-banner">
          <div className="franchise-banner-container">
            <div className="franchise-banner-title">
              <h2>
                Seja um franqueado do
                <span>
                  maior complexo esportivo
                </span>
                de modalidades litorâneas <br />
                em São Paulo.
              </h2>
              <span>Pioneirismo, determinação e muita areia!</span>
            </div>
            <div className={highlightActive ? "be-franchise-form highlight" : "be-franchise-form"}>
              {emailSuccess &&
                <div className="email-success">
                  <span>Recebemos seu e-mail com sucesso !</span>
                </div>
              }
              <div className="franchise-form-title">
                <h2>Seja um Franqueado</h2>
                <p>
                  Preencha o formulário abaixo, esta ficha é seu primeiro passo para conhecer 
                  melhor você.  As informações digitadas aqui são confidenciais e protegidas pela
                  nossa política de privacidade. Após o recebimento desse questionário 
                  entraremos em contato!
                </p>
              </div>
              <form ref={form} onSubmit={sendEmail}>
                <label>
                  Nome
                  <input type="text" name="name" />
                </label>
                <label>
                  Email
                  <input type="email" name="email" />
                </label>
                <label>
                  Celular
                  <input type="tel" name="tel" />
                </label>
                <label>
                  Estado
                  <input type="text" name="estado" />
                </label>
                <label>
                  Cidade
                  <input type="text" name="cidade" />
                </label>
                <label className="input-money">
                  Investimento dísponivel
                  {/* <input type="text" name="investimento" />
                  <span className="money-indicator">R$</span> */}
                  <select name="investimento">
                    <option value="ate450">R$300 a R$450</option>
                    <option value="ate600">R$450 a R$600</option>
                    <option value="acima600">acima de R$600</option>
                  </select>
                </label>
                <button type="submit">Quero ser um franqueado</button>
              </form>
            </div>
          </div>
        </section>
        <section className="riplay-numbers">
          <div className="riplay-numbers-title">
            <h2>Riplay Beach Sports em <span>números</span></h2>
          </div>
          <div className="riplay-numbers-content">
            <ul>
              {numbers.map((number,index)=>(
                <li key={index}>
                  <span>{number.num}</span>
                  <span>{number.title}</span>
                  <span>{number.desc}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className="cta-franchise-btn">
            <button onClick={() => scrollToForm()}>Quero ser um franqueado</button>
          </div>
        </section>
        <section className="franchise-text-box">
          <h2>Nossos <span>Diferenciais</span></h2>
          <p>
            Somos a maior rede de esportes de areia do Mundo, com unidades no Brasil, EUA e Europa. Pioneira no desenvolvimento do Futevolei no Brasil. Estamos no mercado desde 2007. 
          </p>
          <p>
            A Riplay oferece aos seus franqueados todo o suporte para transformar o seu investimento em uma fonte de contentamento e lucratividade. Como Franqueado Riplay você terá apoio em diversas áreas desde a implantação até a gestão de sua unidade.
          </p>
          <div className="cta-franchise-btn">
            <button onClick={() => scrollToForm()}>Quero ser um franqueado</button>
          </div>
        </section>
        {/* <section className="franchise-text-box">
          <h2>Modelos de <span>negócio</span></h2>
          <p>
            Lore ipsum dolor sit amet, consectetur adipiscing elit. Nullam tempus facilisis ultricies. Sed feugiat aliquam mollis. Mauris ac diam vel leo sodales elementum eget nec sem. Maecenas at rutrum eros. Proin sagittis convallis sodales. Nulla condimentum justo lorem, ut euismod nunc feugiat in. Proin consequat laoreet laoreet. 
          </p>
          <p>
            Ut tincidunt nisi sit amet porttitor commodo. Donec sit amet urna luctus, convallis velit eu, ultrices arcu. Nunc eget libero magna. Nunc posuere nisi id nunc tincidunt, sed dapibus ante placerat. Nunc ut commodo lorem. In eu nunc quam. Phasellus sollicitudin posuere faucibus. In commodo nisl at purus egestas sollicitudin. Curabitur at erat vulputate turpis
          </p>
          <div className="cta-franchise-btn">
            <button onClick={() => scrollToForm()}>Quero ser um franqueado</button>
          </div>
        </section>
        <section className="franchise-text-box">
          <h2>Veja <span>o que é riplay</span></h2>
          <p>
            Lore ipsum dolor sit amet, consectetur adipiscing elit. Nullam tempus facilisis ultricies. Sed feugiat aliquam mollis. Mauris ac diam vel leo sodales elementum eget nec sem. Maecenas at rutrum eros. Proin sagittis convallis sodales. Nulla condimentum justo lorem, ut euismod nunc feugiat in. Proin consequat laoreet laoreet. 
          </p>
          <div className="cta-franchise-btn">
            <button onClick={() => scrollToForm()}>Quero ser um franqueado</button>
          </div>
        </section> */}
        <Differentials />
    </div>
  )
}

export default Franchise