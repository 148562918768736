import "./Welcome.css"
import {useWindowScroll} from "react-use"
import { useState, useEffect } from "react"

//Assets
import RiplayLogo from "../../assets/welcome/riplay-logo-big.png"
import BackgroundVideo from "../../assets/welcome/institucional-video.mp4"
import videoPoster from "../../assets/welcome/institucional-video-banner.png"


const Welcome = () => {

  const {y: pageYOffset} = useWindowScroll();
  const [visible, setVisibility] = useState(true);

  useEffect(() => {

    if (pageYOffset > 100){
      setVisibility(false)
    }else{
      setVisibility(true)
    }

  },[pageYOffset])

  return (
    <section className="welcome-main">
      <video autoPlay muted loop className="video-background" poster={videoPoster}>
        <source src={BackgroundVideo} type="video/mp4" />
      </video>
      <div className="opacity-mask"></div>
      <img src={RiplayLogo} className="riplay-logo"/>
      {visible &&
        <div className="btn-scroll-down-holder">
          <div className="container">
            <div className="chevron"></div>
            <div className="chevron"></div>
            <div className="chevron"></div>
          </div>
        </div>
      }
    </section>
  )
}

export default Welcome