//Imports
import { NavLink, Link } from "react-router-dom"
import { useState } from "react"

//Style Sheets
import "./Header.css"

//Assets
import HeaderLogo from "../../assets/header/riplay-logo-header.png"
import MobileNavBtn from "../../assets/header/mobile-nav-btn.png"

const Header = () => {

  const [isVisible, setVisible] = useState(false);

  return (
    <header className="main-header">
      <div className="header-bar">
        <Link to="/">
          <img src={HeaderLogo} alt="Riplay" className="logo-header" />
        </Link>
        <div className="nav-holder">
          <nav>
            <NavLink to="/">Home</NavLink>
            <NavLink to="/sobre-nos">A Riplay</NavLink>
            <NavLink to="/unidades">Unidades</NavLink>
            <NavLink to="/modalidades">Modalidades</NavLink>
            <NavLink to="/seja-franqueado">Seja Franqueado</NavLink>
            {/* <NavLink to="/blog">Blog</NavLink> */}
            {/* <a href="https://riplaysports.com.br/loja/" target="_blank">Loja</a> */}
            <NavLink to="/contato">Contato</NavLink>
          </nav>
          <a href="https://wa.me/+5511939010541" target="_blank" className="schedule-btn">Agendamentos</a>
        </div>
      </div>
      <div className="header-bar-mobile">
        <div className="btn-mobile-holder" onClick={() => setVisible(true)}>
          <img src={MobileNavBtn} />
        </div>
      </div>
      {isVisible &&
        <div className="nav-holder-mobile">
          <button onClick={() => setVisible(false)} className="btn-close-mobile-nav"></button>
          <nav>
            <NavLink to="/" onClick={() => setVisible(false)}>Home</NavLink>
            <NavLink to="/sobre-nos" onClick={() => setVisible(false)}>A Riplay</NavLink>
            <NavLink to="/unidades" onClick={() => setVisible(false)}>Unidades</NavLink>
            <NavLink to="/modalidades" onClick={() => setVisible(false)}>Modalidades</NavLink>
            <NavLink to="/seja-franqueado" onClick={() => setVisible(false)}>Seja Franqueado</NavLink>
            {/* <NavLink to="/blog" onClick={() => setVisible(false)}>Blog</NavLink> */}
            {/* <a href="https://riplaysports.com.br/loja/" target="_blank">Loja</a> */}
            <NavLink to="/contato" onClick={() => setVisible(false)}>Contato</NavLink>
            <a href="https://wa.me/+5511939010541" target="_blank" className="schedule-btn" onClick={() => setVisible(false)}>Agendamentos</a>
          </nav>
        </div>
      }
    </header>
  )
}

export default Header