import "./About.css"

//Components
import PageTitle from "../../components/PageTitle/PageTitle"
import Differentials from "../../components/Differentials/Differentials"
import Partners from "../../components/Partners/Partners"
import FranchiseForm from "../../components/FranchiseForm/FranchiseForm"

//Imgs
import aboutImageEnd from "../../assets/about/about-image2.png"
import aboutHistImg from "../../assets/about/about-our-hist.png"
import mikasaLogo from "../../assets/about/mikasa-logo.png"
import mikasaImg from "../../assets/about/conq-one.png"
import superquadraLogo from "../../assets/about/superquadra-logo.png"
import superquadraImg from "../../assets/about/superquadra-img.png"
import mormaiiLogo from "../../assets/about/mormaii-logo.png"
import mormaiiImg from "../../assets/about/mormaii-img.png"
import bfexpoLogo from "../../assets/about/bfexpo-logo.png"
import bfexpoImg from "../../assets/about/bfexpo-img.png"

const About = () => {
  return (
    <div className="about-page-main">
      <PageTitle value="A Riplay" />
      <div className="text-box">
        <h3>Quem somos</h3>
        <p>
          A Riplay Beach Sports foi a pioneira ao trazer beach sports para a cidade, tornando- se
          o maior complexo esportivo de modalidades litorâneas em São Paulo, com expansão 
          nos Estados Unidos.
        </p>
        <p>
          Nossa metodologia, nossos professores e nossa forma de abraçar os beach sports já
          foram estudados por nossos principais concorrentes que queriam entender o universo 
          e se inserir da melhor forma. Apesar disso, muitos optaram por seguir outros caminhos
          como o de entretenimento (show) e alimentação, mas nós seguimos apostando no que 
          verdadeiramente nos move: o ESPORTE.
        </p>
      </div>
      <img src={aboutImageEnd} alt="Quem somos" className="full-image" />
      <div className="text-box">
        <h3>Nossa história</h3>
        <p>
          Criada em 2007 por José Roberto Rivelino e seus irmãos, a Riplay Sports começou
          como uma Escola de Futebol, focada em formar craques na bola e na escola,
          cidadãos do futuro, que valorizam as relações de amizade e respeito mútuo.
        </p>
        <p>
          Em 2011, o Gilberto Diniz, amigo de José Roberto Rivelino, trouxe para Riplay 
          Sports uma ideia inovadora para capital paulista: formar um Centro de 
          Treinamento de Futevôlei. Com essa parceria, a Riplay Sports foi pioneira ao trazer
          para a cidade um esporte originalmente praticado em cidades litorâneas. 
        </p>
      </div>
      <img src={aboutHistImg} alt="Nossa História" className="full-image" />
      <div className="text-box">
        <h3>Conquistas</h3>
        <div className="image-text-box">
          <img src={mikasaImg} alt="Mikasa" />
          <div className="image-text-box-content">
            <img src={mikasaLogo} alt="Mikasa" />
            <p>
              No início, importávamos a bola Mikasa, até
              então ainda de futebol, para utilizarmos
              em nossas quadras. Porém, ao longo do
              tempo e da parceria, desenvolvemos uma
              bola para ser a oficial para a prática de
              Futevolêi, com a calibragem e modelo
              adequado: FT5.
            </p>
          </div>
        </div>
        <div className="image-text-box">
          <div className="image-text-box-content">
            <img src={superquadraLogo} alt="Super Quadra" />
            <p>
              A primeira areia utilizada pela Riplay não
              tinha a qualidade ideal para a prática dos
              beach sports, desta forma houve a
              necessidade de procurar parceiros para
              ajudar neste quesito. Assim, surgiu a
              experiência com a areia da Mineradora 
              Jundu que, com o crescimento das
              modalidades de esporte de areia, criou sua
              primeira marca esportiva com assessoria
              da Riplay Beach Sports: a Super Quadra 
              Jundu. A Areia desenvolvida para a prática
              de Beach sports. 
            </p>
          </div>
          <img src={superquadraImg} alt="Super Quadra" />
        </div>
        <div className="image-text-box">
          <img src={mormaiiImg} alt="Mormaii" />
          <div className="image-text-box-content">
            <img src={mormaiiLogo} alt="Mormaii" />
            <p>
              Em 2019 começou a parceria com a marca,
              como embaixadores do futevôlei. Para a
              Mormaii e para a Riplay, os esportes de areia,
              representam um estilo de vida único, jovem
              e alegre.
            </p>
          </div>
        </div>
        {/* <div className="image-text-box">
          <div className="image-text-box-content">
            <img src={bfexpoLogo} alt="Mormaii" />
            <p>
              Em 2019 começou a parceria com a marca,
              como embaixadores do futevôlei. Para a
              Mormaii e para a Riplay, os esportes de areia,
              representam um estilo de vida único, jovem
              e alegre.
            </p>
          </div>
          <img src={bfexpoImg} alt="Mormaii" />
        </div> */}
      </div>
      <Differentials />
      {/* <FranchiseForm /> */}
      <Partners />
    </div>
  )
}

export default About