import "./Modalities.css"
import { useState } from "react"

//Components
import PageTitle from "../../components/PageTitle/PageTitle"
import ModalitiesCards from "../../components/ModalitiesCards/ModalitiesCards"
import ModalitieDetail from "../../components/ModalitieDetail/ModalitieDetail"

//Images
import beachtenisimg1 from "../../assets/modalities/beachtennis-bg.png"
import tennis1 from "../../assets/modalities/tennis1.png"
import tennis2 from "../../assets/modalities/tennis2.png"
import tennis3 from "../../assets/modalities/tennis3.png"
import futevolei1 from "../../assets/modalities/futevolei1.png"
import futevolei2 from "../../assets/modalities/futevolei2.png"
import futevolei3 from "../../assets/modalities/futevolei3.png"
import beachvolei1 from "../../assets/modalities/beachvolei1.png"
import beachvolei2 from "../../assets/modalities/beachvolei2.png"
import beachvolei3 from "../../assets/modalities/beachvolei3.png"

//Ambassadors Images
import fredProfile from "../../assets/ambassadors/fred-profile.png"
import albaniProfile from "../../assets/ambassadors/albani-profile.png"
import mileneDominguesProfile from "../../assets/ambassadors/milenedomingues-profile.png"
import luqueProfile from "../../assets/ambassadors/luque-profile.png"
import cerriProfile from "../../assets/ambassadors/cerri-profile.png"
import majuProfile from "../../assets/ambassadors/maju-profile.png"
import donatoProfile from "../../assets/ambassadors/donato-profile.png"
import cappellanesProfile from "../../assets/ambassadors/cappellanes-profile.png"
import robertoCarlosProfile from "../../assets/ambassadors/robertocarlos-profile.png"
import michelBastosProfile from "../../assets/ambassadors/michelbastos-profile.png"
import baroniProfile from "../../assets/ambassadors/baroni-profile.png"
import sarettaProfile from "../../assets/ambassadors/saretta-profile.png"

//Ambassador
const ambassadorsList = [
  {
    name: "Fred",
    arr: "@fred",
    image: fredProfile,
    url: "https://www.instagram.com/fred/",
  },
  {
    name: "Milene Domingues",
    arr: "@milenedomingues",
    image: mileneDominguesProfile,
  },
  {
    name: "Marco Luque",
    arr: "@marcoluque",
    image: luqueProfile,
  },
  {
    name: "Bruninho Cerri",
    arr: "@bruninho",
    image: cerriProfile,
  },
  {
    name: "Marcio Donato",
    arr: "@marciodonato",
    image: donatoProfile,
  },
  {
    name: "João Paulo Cappellanes",
    arr: "@capelareal",
    image: cappellanesProfile,
  },
  {
    name: "Roberto Carlos",
    arr: "@oficialrc3",
    image: robertoCarlosProfile,
  },
  {
    name: "Michel Bastos",
    arr: "@michelbastos",
    image: michelBastosProfile,
  },
  {
    name: "Cristian Baroni",
    arr: "@crisb_16",
    image: baroniProfile,
  },
  {
    name: "Flávio Saretta",
    arr: "@fsaretta",
    image: sarettaProfile,
  },
]

const Modalities = () => {

  const [selectedModalitie, setSelectedModalitie] = useState("")


  //Scroll Down on Mod Click
  const ModalitieClick = (mod) => {

    setSelectedModalitie(mod)
    window.scrollTo({
      top: 1100,
      left: 0,
      behavior: 'smooth'
    });

  }

  return (
    <div className="modalities-main">
        <PageTitle value="Modalidades" />
        <div className="modalities-desc">
          <p>
            Temos centros de treinamento, com estrutura moderna, areia específica e equipamentos atualizados, voltados
            para aulas dos principais esportes de praia. Esse conjunto nos permite oferecer um ambiente descontraído, com
            atividades esportivas de baixo ou alto impacto, de acordo com a escolha do aluno
          </p>
        </div>
        <section className="modalities-cards-main">
            <ul>
                <li onClick={()=>ModalitieClick("beachtennis")}>Be<br />ach<br />ten<br />nis</li>
                <li onClick={()=>ModalitieClick("futevolei")}>Fu<br />te<br />vô<br />lei</li>
                <li onClick={()=>ModalitieClick("beachvolei")}>Be<br />ach<br />vôl<br />ley</li>
                <li onClick={()=>ModalitieClick("funcional")}>Fun<br />cio<br />nal</li>
            </ul>
        </section>
        {selectedModalitie === "beachtennis" &&
          <ModalitieDetail
            value="beachtennis"
            sport="Beach Tennis"
            text="Esporte que está virando um fenômeno em São Paulo, trazendo novos alunos e praticantes para a Riplay. O seu sucesso 
            deve-se pela facilidade com que uma pessoa aprende a jogar e pela diversão que ele proporciona mesmo para quem 
            nunca praticou antes. Modalidade esportiva democrática e que traz inúmeros benefícios à saúde."
            img1={tennis1}
            img2={tennis2}
            img3={tennis3}
          />
        }
        {selectedModalitie === "futevolei" &&
          <ModalitieDetail
            value="futevolei"
            sport="Futevôlei"
            text="Uma das modalidades mais procuradas para aqueles que buscam uma qualidade de vida e se divertir com os amigos em
            um ambiente que nos traz a sensação de estar à beira-mar. Super indicado para a saúde física quanto para a saúde 
            mental. Reduz os níveis de estresse, ajuda a melhorar a concentração e a habilidade de raciocínio."
            img1={futevolei1}
            img2={futevolei2}
            img3={futevolei3}
          />
        }
        {selectedModalitie === "beachvolei" &&
          <ModalitieDetail
            value="beachvolei"
            sport="Beach Volley"
            text="Um esporte dinâmico, em que o Brasil se apresenta como referência e que desenvolve agilidade, velocidade e 
            coordenação aos seus praticantes."
            img1={beachvolei1}
            img2={beachvolei2}
            img3={beachvolei3}
          />
        }
        {selectedModalitie === "funcional" &&
          <ModalitieDetail
            value="funcional"
            sport="Funcional"
            text="Um treino dinâmico, com alto gasto calórico e em um ambiente leve, que traz maior sensação de bem-estar."
            img1={"https://images.unsplash.com/photo-1599058917212-d750089bc07e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDZ8fGd5bXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=400&q=60"}
            img2={"https://images.unsplash.com/photo-1548690312-e3b507d8c110?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTl8fGd5bXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=400&q=60"}
            img3={"https://images.unsplash.com/photo-1534258936925-c58bed479fcb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzV8fGd5bXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=400&q=60"}
          />
        }
        <div className="titled-list-holder">
          <div className="titled-list-holder-content">
            <div className="titled-list">
              <h3>Benefícios dos <span>Beach Sports</span></h3>
              <div className="titled-list-content">
                <ul>
                  <li>1 treino pode queimar até 800 calorias</li>
                  <li>Emagrece e desenvolve agilidade, força e explosão</li>
                  <li>Fazem bem para a mente e emoções</li>
                  <li>O impacto amortecido pela areia apresenta menor risco de lesão do que outras modalidades</li>
                  <li>É para todas as idades</li>
                </ul>
              </div>
            </div>
            <div className="titled-list">
              <h3>Metodologia <span>Riplay</span></h3>
              <p>
                Em 2011 vieram as primeiras ideias para criar nossa própria metodologia, a fim de tornar o 
                beach sports democrático, apostando em:
              </p>
              <div className="titled-list-content">
                <ul>
                  <li>Aprendizagem através da compreensão do jogo</li>
                  <li>Esportes para todos os níveis</li>
                  <li>Metodologia dividida em 4 níveis</li>
                  <li>03 meses de duração para cada nível</li>
                </ul>
              </div>
              <p>
                Para que nossos alunos conseguissem melhorar
              </p>
              <div className="titled-list-content">
                <ul>
                  <li>Desempenho</li>
                  <li>Habilidades técnicas</li>
                  <li>Estratégia e tática</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="ambassadors">
          <h3>Nossos <span>Embaixadores</span></h3>
          <ul>
            {ambassadorsList.map((person, index)=>(
              <li key={index}>
                <img src={person.image} alt={person.name} />
                <p>{person.name}</p>
                <span><a href={person.url} target="_blank">{person.arr}</a></span>
              </li>
            ))}
          </ul>
        </div>
    </div>
  )
}

export default Modalities