import "./Contact.css"
import { Link } from "react-router-dom"
import { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

const Contact = () => {

  const form = useRef();
  const [emailSuccess, setEmailSuccess] = useState(false)

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_bc4f26j', 'template_w7acmb6', form.current, 'HLv3dtry2ORETRo4v')
      .then((result) => {
          console.log(result.text);
          setEmailSuccess(true)
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <div className="contact-main">
      <section className="contact-content">
        <div className="contact-options">
          <h2>Fale com <span>a Riplay</span></h2>
          <p>Escolha a melhor maneira para voce se comunicar conosco</p>
          <div className="contact-options-holder">
            <p>
              Fale com nossa central de Whatsapp
              <span>Encaminharemos você para lorem ipsum dolor sit amet</span>
            </p>
            <a href="https://wa.me/+5511939010541" target="_blank" className="whatsapp">Whatsapp Central</a>
            <p>Ou escolha uma unidade</p>
            <Link to="/unidades" className="outline">Ver unidades</Link>
          </div>
        </div>
        <div className="contact-form-holder">
          {emailSuccess &&
          <div className="email-success">
            <span>Recebemos seu e-mail com sucesso!</span>
          </div>
          }
          <p>
            Se preferir, preencha o formulário abaixo
            <span>Responderemos o mais breve possível</span>
          </p>
          <form ref={form} onSubmit={sendEmail}>
             <label>
               Nome
               <input type="text" name="name" />
             </label>
             <label>
               Email
               <input type="email" name="email" />
             </label>
             <label>
               Telefone
               <input type="text" name="tel" />
             </label>
             <label>
               Whatsapp
               <input type="text" name="whatsapp" />
             </label>
             <label className="subject">
               Assunto
               <input type="text" name="subject" />
             </label>
             <label className="subject">
               Mensagem
               <textarea name="message" />
             </label>
             <button>Enviar</button>
          </form>
        </div>
      </section>
    </div>
  )
}

export default Contact