import './App.css';

import {useState, useEffect} from "react"

//Firebase
import { db } from "./firebase-config"
import { auth } from "./firebase-config"
import { onAuthStateChanged } from 'firebase/auth';
import { collection, query, where, doc, getDocs, orderBy } from "firebase/firestore";

//React Router
import {BrowserRouter, Routes, Route} from "react-router-dom"

//Components
import Header from "./components/Header/Header"
import Footer from './components/Footer/Footer';
import ScrollToTop from "./components/ScrollToTop/ScrollToTop"

//Context
import { AuthProvider } from './context/AuthContext';
import { PostsProvider } from './context/PostsContext';

//Pages
import Home from "./pages/home/Home"
import About from "./pages/about/About"
import Units from "./pages/units/Units"
import Modalities from "./pages/modalities/Modalities"
import Franchise from "./pages/franchise/Franchise"
import Contact from "./pages/contact/Contact"
import Page404 from "./pages/404/Page404"
import Blog from './pages/blog/Blog';
import Admin from './pages/admin/Admin';
import AdmPanel from './pages/admPanel/AdmPanel';
import BlogCategory from './pages/BlogCategory/BlogCategory';
import LatestCategory from './pages/LatestCategory/LatestCategory';
import PostDetail from './pages/postDetail/PostDetail';
import BlogSearch from './pages/blogSearch/BlogSearch';



function App() {

  // Set User by firebase auth state changed
  const [user, setUser] = useState(undefined)

  useEffect(() => {
    onAuthStateChanged(auth, (user) =>{
      setUser(user)
    })
  },[auth])
  // END

  // Set Posts by firestore
  const [posts, setPosts] = useState(null)
  const [categories, setCategories] = useState(null)

  useEffect(() => {
    const getPosts = async() => {
      const q = query(collection(db, "posts"), orderBy("createdAt"));

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setPosts(
          querySnapshot.docs.map((doc) =>({
              id: doc.id,
              ...doc.data(),
          }))
        )
      });
    }

    const getCategories = async() => {
      const q = query(collection(db, "categories"), where("listed", "==", true));

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setCategories(
          querySnapshot.docs.map((doc) =>({
              id: doc.id,
              ...doc.data(),
          }))
        )
      });
    }

    getCategories()
    getPosts()
  },[])
  // END

  return (
    <div className="App">
      <AuthProvider value={{user}}>
        <PostsProvider value={{posts,categories}}>
          <BrowserRouter>
            <Header />
            <ScrollToTop>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/sobre-nos" element={<About />} />
                <Route path="/unidades" element={<Units />} />
                <Route path="/modalidades" element={<Modalities />} />
                <Route path="/seja-franqueado" element={<Franchise />} />
                <Route path="/contato" element={<Contact />} />

                <Route path="/blog" element={<Blog />} />
                <Route path="/blog/:categoryName" element={<BlogCategory />} />
                <Route path="/blog/todos" element={<LatestCategory />} />
                <Route path="/blog/busca" element={<BlogSearch />} />
                <Route path="/post/:postId" element={<PostDetail />} />
                <Route path="/admin" element={<Admin />} />
                <Route path="/panel" element={user && <AdmPanel />} />
                
                <Route path="*" element={<Page404 />} />
              </Routes>
            </ScrollToTop>
            <Footer />
          </BrowserRouter>
        </PostsProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
