import "./AboutCall.css"
import aboutImage from "../../assets/about/about-image.png"

const AboutCall = () => {
  return (
    <section className="aboutcall-main">
        <h2>Riplay <span>Beach Sports</span></h2>
        <p>Somos a maior referência de Arenas de Beach Sports no Brasil.
        Seja para iniciantes, atletas ou para as pessoas que buscam
        um espaço para curtir o clima praieiro.</p> 
        <div className="imageBackground"></div>
    </section>
  )
}

export default AboutCall