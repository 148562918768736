import "./OurLocations.css"

import ourLocationsImg from "../../assets/ourLocations/ourLocationsImg.png"
import CtaLink from "../CtaLink/CtaLink"

const OurLocations = () => {
  return (
    <section className="our-locations-main">
        <div className="our-locations-container">
            <div className="our-locations-content">
                <h3>Onde<br />estamos</h3>
                <ul>
                    <li>ORLANDO/USA</li>
                    <li>São Paulo</li>
                    <li>Granja Viana</li>
                </ul>
                <CtaLink label="Nossas Unidades" linkto="/unidades" align="right" />
            </div>
        </div>
    </section>
  )
}

export default OurLocations