import PostSimple from "../PostSimple/PostSimple"
import "./PostsSection.css"

// Context
import { usePostsValue } from "../../context/PostsContext"

import { useState, useEffect } from "react"

import { Link } from "react-router-dom"


const PostsSection = ({category}) => {

    const { posts } = usePostsValue() //Get Posts by Context
    const [ filteredPosts, setFilteredPosts ] = useState(null)

    useEffect(() => {
      
        const FilterAndOrderPosts = () => {
            const filtered = posts.filter(item => item.category == category)
            const ordered = [...filtered].reverse()
            setFilteredPosts(ordered);
        }

        posts && FilterAndOrderPosts()

    }, [posts])
    
    const FormatDate = (date) => {
        let newdate = new Date(date*1000).toString()
        let newdateArray = newdate.split(" ")
        newdateArray.splice(0,1)
        newdateArray = newdateArray.splice(0,3)
        let formatedDate = [newdateArray[1], newdateArray[0], newdateArray[2]].toString().replace(/,/g, ' ')
        
        return formatedDate
    }

    return (
        <section className="posts-section-main">
            <h2 className="section-title">
                <span className="section">{category}</span> <span className="caption">Fique por<br /> dentro</span>
            </h2>
            {filteredPosts &&
                <div className="posts-section-holder">
                    <div className="post-section-holder-left">
                        {filteredPosts[1] && <PostSimple post={filteredPosts[1]} />}
                        {filteredPosts[2] && <PostSimple post={filteredPosts[2]} />}
                    </div>
                    <div className="post-section-middle-post">
                        <img src={filteredPosts[0].img} alt={filteredPosts[0].title} />
                        <div className="post-content-highlight">
                            <h2>
                                <span>
                                {filteredPosts[0].category}
                                </span>
                                {filteredPosts[0].title}
                            </h2>
                            <div className="post-author-date">
                                <p>
                                <span>Escrito por:</span>
                                {filteredPosts[0].author}
                                </p>
                                <span>
                                {FormatDate(filteredPosts[0].createdAt.seconds)}
                                </span>
                            </div>
                        </div>
                        <div className="post-hover">
                            <svg xmlns="http://www.w3.org/2000/svg" width="23.041" height="23.084" viewBox="0 0 23.041 23.084">
                                <g id="Grupo_170" data-name="Grupo 170" transform="translate(-323.086 -1166.83)">
                                <path id="Caminho_296" data-name="Caminho 296" d="M0,0,7.832,8,0,16" transform="translate(327.568 1174.075) rotate(-45)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                                <line id="Linha_52" data-name="Linha 52" x1="14.078" y2="14.037" transform="translate(324.5 1174.463)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="2"/>
                                </g>
                            </svg>
                        </div> 
                    </div>
                    <div className="post-section-holder-right">
                        {filteredPosts[3] && <PostSimple post={filteredPosts[3]} />}
                        {filteredPosts[4] && <PostSimple post={filteredPosts[4]} />}
                    </div>
                </div>
            }
            <Link to={`/blog/${category}`} className="display-all-posts">Ver todos</Link>
        </section>
    )
}

export default PostsSection