import "./BlogNavBar.css"

// Context
import { usePostsValue } from "../../context/PostsContext"

import { Link } from "react-router-dom"
import { useState } from "react"

const BlogNavBar = () => {

    const { categories } = usePostsValue() //Get Posts by Context
    const [displayMobile, setDisplayMobile] = useState(false)
        
    return (
        <div className="blog-nav-bar-main">
            <ul className="nav-main-blog nav-desktop">
                <li>
                    <Link to="/blog/busca" className="search">
                    <svg xmlns="http://www.w3.org/2000/svg" width="19.914" height="20.914" viewBox="0 0 22.914 23.914">
                        <g id="Grupo_1" data-name="Grupo 1" transform="translate(-774.086 -310)">
                            <g id="Elipse_1" data-name="Elipse 1" transform="translate(777 310)" fill="none" stroke="#fff" strokeWidth="2">
                            <circle cx="10" cy="10" r="10" stroke="none"/>
                            <circle cx="10" cy="10" r="9" fill="none"/>
                            </g>
                            <line id="Linha_1" data-name="Linha 1" y1="5" x2="5" transform="translate(775.5 327.5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2"/>
                        </g>
                    </svg>

                    </Link>
                </li>
                <li>
                    <Link to="/blog">
                        Home
                    </Link>
                </li>
                {categories && categories.map((category,index)=>(
                    <li key={index}>
                        <Link to={`/blog/${category.name}`}>
                            {category.name}
                        </Link>
                    </li>
                ))}
            </ul>
            <ul className="nav-main-blog nav-mobile">
                <li>
                    <Link to="/blog/busca" className="search">
                    <svg xmlns="http://www.w3.org/2000/svg" width="19.914" height="20.914" viewBox="0 0 22.914 23.914">
                        <g id="Grupo_1" data-name="Grupo 1" transform="translate(-774.086 -310)">
                            <g id="Elipse_1" data-name="Elipse 1" transform="translate(777 310)" fill="none" stroke="#fff" strokeWidth="2">
                            <circle cx="10" cy="10" r="10" stroke="none"/>
                            <circle cx="10" cy="10" r="9" fill="none"/>
                            </g>
                            <line id="Linha_1" data-name="Linha 1" y1="5" x2="5" transform="translate(775.5 327.5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2"/>
                        </g>
                    </svg>

                    </Link>
                </li>
                <li>
                    <Link to="/blog">
                        Home
                    </Link>
                </li>
                <li onClick={()=>setDisplayMobile(true)}>
                    Categorias
                </li>
            </ul>
            {displayMobile &&
                <div className="modal-mobile-nav">
                    <h2><span>Categorias</span> do nosso blog</h2>
                    <ul>
                        {categories && categories.map((category,index)=>(
                            <li key={index}>
                                <Link to={`/blog/${category.name}`}  onClick={()=>setDisplayMobile(false)}>
                                    {category.name}
                                </Link>
                            </li>
                        ))}
                    </ul>
                    <button onClick={()=>setDisplayMobile(false)} />
                </div>
            }
            
        </div>
    )
}

export default BlogNavBar