import "./Admin.css"

import { useState, useEffect } from "react"

import { Link, useNavigate } from "react-router-dom"

// Firebase
import { signInWithEmailAndPassword } from "firebase/auth"
import { auth } from "../../firebase-config"

// Context
import { useAuthValue } from "../../context/AuthContext"

const Admin = () => {

  let navigate = useNavigate();
  const { user } = useAuthValue() //Get User by Context
  const [loginEmail, setLoginEmail] = useState("")
  const [loginPassword, setLoginPassword] = useState("")
  const [loginError, setLoginError] = useState(null) //Error
  const [loading, setLoading] = useState(false) //Loading

  // Login
  const login = async (e) => {

    setLoginError(null)
    setLoading(true)

    try{
      await signInWithEmailAndPassword(auth, loginEmail, loginPassword)
    }catch(err){
      if(err.message.includes("invalid-email")){
        setLoginError("E-mail inválido")
      }else if(err.message.includes("user-not-found")){
        setLoginError("Usuário incorreto")
      }else if(err.message.includes("wrong-password")){
        setLoginError("Senha incorreta")
      }else if(err.message.includes("too-many-requests")){
        setLoginError("Muitas tentativas de login com essa conta, desabilitamos ela temporariamente. Tente novamente em alguns minutos.")
      }else if(!loginPassword){
        setLoginError("Digite sua senha")
      }else{
        setLoginError("Houve um erro, tente novamente")
      }
    }

    setLoading(false)
  }

  // Redirect if Logged
  useEffect(() => {
    const redirectIfLogged = () => {
      if(user){
        navigate("/panel")
      }
    }
    redirectIfLogged()
  }, [user])

  return (
    <div className="admin-main">
        <div className="login-box">
            <h2>Login</h2>
            <input type="email" name="email" placeholder="Email" onChange={(e)=>{setLoginEmail(e.target.value)}} />
            <input type="password" name="password" placeholder="Senha" onChange={(e)=>{setLoginPassword(e.target.value)}} />
            {!loading && <button onClick={login}>Entrar</button>}
            {loading && <button className="await">Aguarde...</button>}
            <span className="error">{loginError}</span>
        </div>
    </div>
  )
}

export default Admin