import "./BlogSearch.css"
import BlogNavBar from '../../components/BlogNavBar/BlogNavBar'

// Context
import { usePostsValue } from "../../context/PostsContext"

// Components
import { useState, useEffect } from "react"
import PostSimple from "../../components/PostSimple/PostSimple"

const BlogSearch = () => {

    const [searchValue, setSearchValue] = useState(null)
    const [searchValueResult, setSearchValueResult] = useState(null)
    const { posts } = usePostsValue() //Get Posts by Context
    const [ filteredPosts, setFilteredPosts ] = useState(null)
    const [ orderedPosts, setOrderedPosts ] = useState(null)

    const handleSearchClick = (e) => {
        e.preventDefault()
        setSearchValueResult(searchValue)
        SearchResult(searchValue)
    }

    useEffect(() => {
      
        const FilterAndOrderPosts = () => {
            const ordered = [...posts].reverse()
            setFilteredPosts(ordered);
            setOrderedPosts(ordered)
        }

        posts && FilterAndOrderPosts()

    }, [posts])

    const SearchResult = (value) => {
        
        if(value == null || value == undefined || value == ""){
            setFilteredPosts(orderedPosts)
        }else{
            const filtered = orderedPosts.filter(item => item.postTags && item.postTags.toString().includes(value))
            setFilteredPosts(filtered)
        }

    }
    

    return (
        <div className="blog-search-main">
            <BlogNavBar />
            <div className="blog-search-wrapper">
                <form className="search-bar">
                    <input type="text" placeholder="Escreva aqui sua busca..." onChange={(e)=>setSearchValue(e.target.value)} />
                    <button type="submit" onClick={(e) => handleSearchClick(e)}>Buscar</button>
                </form>
                <h2><span>Resultados para</span> {searchValueResult ? searchValueResult : "Todos"}</h2>
                <ul>
                    {filteredPosts && filteredPosts.length > 0 ? filteredPosts.map((post, index)=>(
                        <li key={index}>
                            <PostSimple post={post} />
                        </li>
                    ))
                    :
                    <span className="not-found-msg">Não foram encontrados resultados para <span>{searchValueResult}</span></span>                    
                    }
                </ul>
            </div>
        </div>
    )
}

export default BlogSearch