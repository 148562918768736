import "./Footer.css"
import { Link } from "react-router-dom"

// Imagens
import facebookIcon from "../../assets/header/facebook-icon.png"
import instaIcon from "../../assets/header/instagram-icon.png"
import youtubeIcon from "../../assets/header/youtube-icon.png"
import twitterIcon from "../../assets/header/twitter-icon.png"
import tiktokIcon from "../../assets/header/tiktok-icon.png"
import riplayLogoFooter from "../../assets/header/riplay-logo-footer.png"
import khousePhygital from "../../assets/header/khouse-phygital.png"
import unidades from "../../data/unidades"


const Footer = () => {
  return (
    <footer>
        <div className="logo-holder">
            <img src={riplayLogoFooter} alt="Riplay" />
        </div>
        <div className="link-list-content">
            <div className="link-list-holder">
                <span>Navegue</span>
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/sobre-nos">A Riplay</Link></li>
                    <li><Link to="/unidades">Unidades</Link></li>
                    <li><Link to="/modalidades">Modalidades</Link></li>
                    <li><Link to="/seja-franqueado">Seja Franqueado</Link></li>
                    {/* <li><Link to="/">Blog</Link></li> */}
                    <li><a href="https://riplaysports.com.br/loja/" target="_blank">Loja</a></li>
                    <li><Link to="/contato">Contato</Link></li>
                </ul>
            </div>
            <div className="link-list-holder">
                <span>Unidades</span>
                <ul>
                    {unidades.map((unit, index)=>(
                        <li key={index}>
                            <Link to="/unidades">{unit.unidade}</Link>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="link-list-holder">
                <span>Redes Sociais</span>
                <ul className="icons-list">
                    <li>
                        <a href="https://www.facebook.com/riplaysports/" target="_blank">
                            <img src={facebookIcon} alt="Facebook" />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/riplaysports/" target="_blank">
                            <img src={instaIcon} alt="Instagram" />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.youtube.com/c/RiplaySportsotemplodofutevolei" target="_blank">
                            <img src={youtubeIcon} alt="YouTube" />
                        </a>
                    </li>
                    <li>
                        <a href="https://twitter.com/riplaysports" target="_blank">
                            <img src={twitterIcon} alt="Twitter" />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.tiktok.com/@riplaysports" target="_blank">
                            <img src={tiktokIcon} alt="TikTok" />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div className="copyright">
            <div className="copyright-content">
                <p>
                    Riplay Beach Sports - 2022
                    <span>Todos os direitos reservados</span>
                </p>
                <a href="https://khouse.company/" target="_blank">
                    <div className="powered-by-khouse">
                        <span>Powered by:</span>
                        <img src={khousePhygital} alt="KhousePhygital" />
                    </div>
                </a>
            </div>
        </div>
    </footer>
  )
}

export default Footer