import "./Differentials.css"

import thumb1 from "../../assets/about/thumb-img-1.png"
import thumb2 from "../../assets/about/thumb-img-2.png"
import thumb3 from "../../assets/about/thumb-img-3.png"

const Differentials = () => {
  return (
    <section className="differentials-main">
        <h3>O que você <span>encontra aqui</span></h3>
        <div className="differentials-content">
            <div className="differential-content-container">
                <div className="differential-content-holder">
                    <img src={thumb1} alt="Ambiente de Transformação" />
                    <h4>Transformação</h4>
                    <p>
                        Ambiente de transformação 
                        dentro de grandes
                        centros urbanos
                    </p>
                </div>
            </div>
            <div className="differential-content-container">
                <div className="differential-content-holder">
                    <img src={thumb2} alt="Ambiente de Transformação" />
                    <h4>Respire novos ares</h4>
                    <p>
                        Nova forma de encarar o dia a dia na cidade
                    </p>
                </div>
            </div>
            <div className="differential-content-container">
                <div className="differential-content-holder">
                    <img src={thumb3} alt="Ambiente de Transformação" />
                    <h4>Qualidade de vida </h4>
                    <p>
                        Mais leveza, saúde, descontração e esporte
                    </p>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Differentials