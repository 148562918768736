import "./AdmPanel.css"

import { useEffect, useState } from "react"

// Firebase
import { db } from "../../firebase-config"
import { collection, getDocs, query, orderBy, onSnapshot, where } from "firebase/firestore";
import { auth } from "../../firebase-config"
import { signOut } from "firebase/auth"

// Router
import { Link, useNavigate } from "react-router-dom"

// Components
import AdmPanelPosts from "../../components/AdmPanelPosts/AdmPanelPosts"
import AdmPanelAuthors from "../../components/AdmPanelAuthors/AdmPanelAuthors";
import AdmPanelCategories from "../../components/AdmPanelCategories/AdmPanelCategories";


const AdmPanel = () => {

    let navigate = useNavigate(); // Use Navigate to change page by function

    // All Posts
    const [allPosts, setAllPosts] = useState(null)
    // Authors
    const [allAuthors, setAllAuthors] = useState(null)
    // Categories
    const [allCategories, setAllCategories] = useState(null)
    // Tabs
    const [activeTab, setActiveTab] = useState("posts")

    //Logout
    const logout = async () => {
        await signOut(auth)
        navigate("/admin")
    }

    // Get Documents
    useEffect(() => {

        //get posts
        const getDocuments = async () => {

            let q

            q = await query(collection(db, "posts"), orderBy("createdAt"))

            await onSnapshot(q, (querySnapshot) => {
                setAllPosts(
                    querySnapshot.docs.map((doc) =>({
                        id: doc.id,
                        ...doc.data(),
                    }))
                )
            })
        }

        //get authors
        const getAuthors = async () => {

            let q

            q = await query(collection(db, "authors"))

            await onSnapshot(q, (querySnapshot) => {
                setAllAuthors(
                    querySnapshot.docs.map((doc) =>({
                        id: doc.id,
                        ...doc.data(),
                    }))
                )
            })
        }

        //get categories
        const getCategories = async () => {

            let q

            q = await query(collection(db, "categories"))

            await onSnapshot(q, (querySnapshot) => {
                setAllCategories(
                    querySnapshot.docs.map((doc) =>({
                        id: doc.id,
                        ...doc.data(),
                    }))
                )
            })
        }

        getCategories()
        getDocuments()
        getAuthors()
    }, [])
    

    return (
        <div className="admpanel-main">
            <div className="panel-top-bar">
                <div className="title-bar">
                    <span className="title">Painel de controle</span>
                    <span className="welcome">Olá, <span>Riplay</span></span>
                </div>
                <div className="panel-navbar">
                    <ul>
                        <li className={activeTab == "posts" ? "active" : ""} onClick={()=>setActiveTab("posts")}>Posts</li>
                        <li className={activeTab == "authors" ? "active" : ""} onClick={()=>setActiveTab("authors")}>Autores</li>
                        <li className={activeTab == "categories" ? "active" : ""} onClick={()=>setActiveTab("categories")}>Categorias</li>
                    </ul>
                    <span onClick={logout}>Logout</span>
                </div>
            </div>
            {activeTab == "posts" && allPosts && <AdmPanelPosts posts={allPosts} authors={allAuthors} categories={allCategories} />}
            {activeTab == "authors" && allAuthors && <AdmPanelAuthors authors={allAuthors} />}
            {activeTab == "categories" && allCategories && <AdmPanelCategories categories={allCategories} />}
        </div>
    )
}

export default AdmPanel