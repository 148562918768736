import CtaLink from "../CtaLink/CtaLink"
import "./ContactUsCall.css"

const ContactUsCall = () => {
  return (
    <section className="contact-us-call-main">
        <div className="contact-us-call-content">
            <h3>Fale<span>Conosco</span></h3>
            <CtaLink label="Fale Conosco" linkto="/contato" />
        </div>
    </section>
  )
}

export default ContactUsCall