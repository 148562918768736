import "./PostDetail.css"

// Context
import { usePostsValue } from "../../context/PostsContext"

import {useParams, Link} from "react-router-dom"
import BlogNavBar from '../../components/BlogNavBar/BlogNavBar'
import { useState, useEffect } from "react"
import PostSimple from "../../components/PostSimple/PostSimple"

const PostDetail = () => {

    const { posts } = usePostsValue() //Get Posts by Context
    const { postId } = useParams()
    const [ post, setPost ] = useState(null)

    useEffect(() => {
        const FindPost = () => {
            const postFound = posts.find(post => post.id == postId)
            setPost(postFound)
        }

        posts && FindPost();
    }, [posts,postId])
    
    const FormatDate = (date) => {
        let newdate = new Date(date*1000).toString()
        let newdateArray = newdate.split(" ")
        newdateArray.splice(0,1)
        newdateArray = newdateArray.splice(0,3)
        let formatedDate = [newdateArray[1], newdateArray[0], newdateArray[2]].toString().replace(/,/g, ' ')
        
        return formatedDate
    }

    // const formatBody = (body) => {
    //     const splitted = body.split('{paragrafo}')
    //     return(
    //         <>
    //             {splitted.map((paragraph, index)=>(
    //                 <p key={index}>
    //                     {paragraph}
    //                 </p>
    //             ))}
    //         </>
    //     )
    // }

    const formatBody = (body) => {
        const splitted = body.split('|')
        return(
            <>
                {splitted.map((paragraph, index)=>{
                    if(paragraph.slice(0,1) == "{"){
                        if(paragraph.slice(0,2) == "{p"){
                            return
                        }else if(paragraph.slice(0,2) == "{i"){
                            const imageUrl = paragraph.replace('{img:', '').replace('}','')
                            return(
                                <p key={index}>
                                    <img src={imageUrl} alt="imagem" />
                                </p>
                            )
                        }else if(paragraph.slice(0,2) == "{l"){
                            const linkUrl = paragraph.replace('{link:', '').replace('}','')
                            return(
                                <p key={index}>
                                    <a href={linkUrl} target="_blank">Acesse o Link <span>{linkUrl}</span></a>
                                </p>
                            )
                        }
                    }else{
                        return(
                            <p key={index}>
                                {paragraph}
                            </p>
                        )
                    }
                })}
            </>
        )
    }

    return (
        <div className="post-detail-main">
            {post &&
                <>
                    <div className="post-image" style={{ backgroundImage: `url(${post.img})` }} />
                    <div className="post-detail-wrap">
                        <div className="post-content">
                            <div className="post-title">
                                <Link to={`/blog/${post.category}`}>
                                    <h2>{post.category}</h2>
                                </Link>
                                <h1>{post.title}</h1>
                                <span>{FormatDate(post.createdAt.seconds)}</span>
                            </div>
                            {formatBody(post.body)}
                        </div>
                        <div className="right-bar">
                            <div className="author-box">
                                <div className="author-infos">
                                    <img src={post.authorImg} alt={post.author} />
                                    <p>
                                        Escrito por:
                                        <span>{post.author}</span>
                                    </p>
                                </div>
                                <p>
                                    {post.authorDesc}
                                </p>
                            </div>
                        </div>
                        
                    </div>
                </>
            }
            
        </div>
    )
}

export default PostDetail