import "./Home.css"

//Components
import Welcome from "../../components/Welcome/Welcome.js"
import AboutCall from "../../components/AboutCall/AboutCall.js"
import ModalitiesCards from "../../components/ModalitiesCards/ModalitiesCards.js"
import Values from "../../components/Values/Values.js"
import BeRiplay from "../../components/BeRiplay/BeRiplay"
import OurUnitsCall from "../../components/OurUnitsCall/OurUnitsCall"
import OurLocations from "../../components/OurLocations/OurLocations"
import ContactUsCall from "../../components/ContactUsCall/ContactUsCall"
import RiplayStoreCall from "../../components/RiplayStoreCall/RiplayStoreCall"

const Home = () => {
  return (
    <>
      <Welcome />
      <AboutCall />
      <ModalitiesCards />
      <Values />
      <OurUnitsCall />
      <BeRiplay />
      <OurLocations />
      <ContactUsCall />
      <RiplayStoreCall />
    </>
  )
}

export default Home