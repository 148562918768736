import "./BlogCategory.css"

// Context
import { usePostsValue } from "../../context/PostsContext"

import {useParams} from "react-router-dom"
import BlogNavBar from '../../components/BlogNavBar/BlogNavBar'
import { useState, useEffect } from "react"
import PostSimple from "../../components/PostSimple/PostSimple"

const BlogCategory = () => {

    const { posts } = usePostsValue() //Get Posts by Context
    const { categoryName } = useParams()
    
    const [ filteredPosts, setFilteredPosts ] = useState(null)

    useEffect(() => {
      
        const FilterAndOrderPosts = () => {
            const filtered = posts.filter(item => item.category == categoryName)
            const ordered = [...filtered].reverse()
            setFilteredPosts(ordered);
        }

        posts && FilterAndOrderPosts()

    }, [posts, categoryName])

    return (
        <div className="blog-category-page-main">
            <BlogNavBar />
            <div className="blog-category-page-main-wrapper">
                <h2 className="section-title">
                    <span className="section">{categoryName}</span> <span className="caption">Fique por<br /> dentro</span>
                </h2>
                <ul>
                    {filteredPosts && filteredPosts.map((post)=>(
                        <PostSimple post={post} />
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default BlogCategory