import "./PostSimple.css"
import { Link } from "react-router-dom"

const PostSimple = ({post}) => {

    const FormatDate = (date) => {
        let newdate = new Date(date*1000).toString()
        let newdateArray = newdate.split(" ")
        newdateArray.splice(0,1)
        newdateArray = newdateArray.splice(0,3)
        let formatedDate = [newdateArray[1], newdateArray[0], newdateArray[2]].toString().replace(/,/g, ' ')
        
        return formatedDate
    }

    return (
        <div className="post-simple-main">
            <div className="post-content">
                <div className="title">
                <span className="date">{FormatDate(post.createdAt.seconds)}</span>
                <h2>
                    <span>
                    {post.category}
                    </span>
                    {post.title}
                </h2>
                </div>
                <p className="author">
                <span>Escrito por:</span>
                {post.author}
                </p>
            </div>
            <Link to={`/post/${post.id}`} className="post-hover">
                <svg xmlns="http://www.w3.org/2000/svg" width="23.041" height="23.084" viewBox="0 0 23.041 23.084">
                    <g id="Grupo_170" data-name="Grupo 170" transform="translate(-323.086 -1166.83)">
                    <path id="Caminho_296" data-name="Caminho 296" d="M0,0,7.832,8,0,16" transform="translate(327.568 1174.075) rotate(-45)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                    <line id="Linha_52" data-name="Linha 52" x1="14.078" y2="14.037" transform="translate(324.5 1174.463)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="2"/>
                    </g>
                </svg>
            </Link> 
        </div>
    )
}

export default PostSimple